@import '../../src/scss/utilities/breakpoints';
@import '../../src/scss/variables';

.blog-references {
    @apply flex flex-col mt-6 px-4;
    overflow-wrap: break-word;
    word-break: break-all;
    font-size: $font-small;

    a {
        text-decoration: none;
        font-family: $font-maisonneue-book;
        font-size: $font-small;
    }
}

.blog-references__header {
    font-family: $font-maisonneue-demi;
    font-size: $font-small;
}

.blog-references__content {
    display: none;
    overflow: hidden;

    &.open {
        display: flex;
        flex-direction: column;
    }
}
