@import 'src/scss/utilities/breakpoints';

.card {
    @apply mx-auto mb-4 mt-20 max-w-sm rounded-2xl bg-white p-4 text-center shadow-sm;
    @apply lg:mt-20;
}

.card--thumbnail {
    @apply mx-auto -mt-20 mb-4 h-32 w-32 rounded-full border-8 border-white bg-blue-light object-cover;
    @apply lg:h-32 lg:w-32;
}

.card--name {
    @apply font-demi text-regular text-green-dark;
}
.card--title {
    @apply text-sm text-grey-400;
}
.card--description {
    @apply px-2 pt-4 text-sm;
}

.box {
    @apply mb-4 rounded-xl bg-white p-4 shadow-sm;
    :global(.heading) {
        &:after {
            content: '';
            @apply mb-4 mt-2 block w-6 border-t-2 border-t-green-dark;
        }
        > * {
            @apply text-regular tracking-tight;
        }
    }
}

.percentage {
    @apply mx-auto max-w-sm text-center md:bg-sand-light md:shadow-none;
}

.progress-bar {
    @apply mb-4 rounded-lg border-4 border-green-dark;
    @apply md:mx-auto md:max-w-[80%];
    span {
        @apply border-green-dark bg-green-dark pt-1 font-heading text-lg tracking-tight;
        @include phone-only {
            @apply justify-start pl-2 pt-1 text-lg;
        }
    }
}

.boxes {
    @apply flex flex-wrap lg:mx-auto lg:max-w-2xl;
    .box1,
    .box2,
    .box4 {
        @apply w-full md:w-1/2;
        > p {
            // @apply text-sm;
        }
    }
    .box3 {
        @apply w-full;
    }
    .cta-box {
        @apply w-full text-center md:w-1/2;
        @apply flex flex-wrap items-center justify-center;
        p {
            @apply mb-4 mt-auto w-full text-center xl:px-8;
        }
        a {
            @apply mb-auto;
        }
    }
}

.box-percentages {
    @apply flex flex-wrap py-4 sm:flex-nowrap sm:items-center sm:space-x-4 sm:py-0;
    > div {
        @apply w-full sm:w-1/2;
        @apply flex items-center space-x-2;
        p {
            @apply pr-4;
        }
    }
}

.ring {
    :global(.progress-ring--stroke) {
        @apply stroke-grey-200;
    }
    :global(.progress-ring--circle) {
        @apply stroke-green;
    }
}

.box-mixed {
    @apply grid grid-cols-1 gap-x-4 sm:grid-cols-2;
    .box-mixed--item {
        @apply mb-4 flex flex-nowrap items-center space-x-4;
    }
    .stat-box {
        @apply inline-flex min-w-[110px] flex-nowrap justify-center space-x-1;
    }
    p {
        @apply text-sm md:text-base;
    }
}
.references {
    @apply px-4 pt-8 text-xs lg:mx-auto lg:max-w-2xl;
    > li,
    a {
        @apply mb-3 text-grey-400 hover:text-green-dark md:mb-1;
    }
    a {
        line-break: anywhere;
    }
}
