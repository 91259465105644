@import '../../scss/utilities/breakpoints';
@import '../../scss/variables';

.call-to-action {
    @apply hidden md:inline-block;
}

.wrapper {
    @apply container;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include md-up {
        flex-direction: row;
        align-items: center;
    }

    .heading-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    .image-container__desktop {
        flex: 1;
        display: none;
        @include md-up {
            display: flex;
        }
        img {
            object-fit: contain;
            align-self: flex-end;
            border-radius: 1rem;
        }
    }

    .image-container__mobile {
        display: flex;
        justify-content: center;
        @include md-up {
            display: none;
        }
        img {
            border-radius: 1rem;
        }
    }
}
