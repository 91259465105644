@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';

.doctor-plan {
    margin-bottom: 4rem;
    section:nth-child(2n + 3) {
        background-color: $sand-light;
    }

    .note-section {
        padding: 0 1rem 2rem 1rem;
        @include md-up {
            padding: 0 2rem 3rem 2rem;
        }
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @include md-up {
            flex-direction: row;
        }
        @include lg-up {
            .note {
                padding-right: 4rem;
            }
        }
        .plan-title {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 1rem 0;
            @include md-up {
                padding: 2rem 0;
                align-items: center;
                justify-content: center;
                h1,
                small {
                    text-align: center;
                }
            }
            small {
                margin-bottom: 1rem;
                color: $grey-400;
            }
        }
        > * {
            flex: 1;
        }
    }
    .ingredients-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            text-align: center;
            max-width: 576px;
        }
        h5 {
            text-align: center;
            font-family: $font-family-main;
        }
        gap: 1.5rem;
        padding: 2rem 1rem;
        @include md-up {
            padding: 3rem 1rem;
        }
    }
    .goal-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
            text-align: center;
        }
        gap: 1.5rem;
        padding: 2rem 1rem;
        @include md-up {
            padding: 3rem 1rem;
            text-align: center;
        }
    }
    .faq-section {
        h5 {
            text-align: center;
            font-family: $font-family-main;
        }
        padding: 2rem 1rem;
        @include md-up {
            padding: 3rem 2rem;
        }
        img {
            align-self: center;
            display: none;
            @include md-up {
                display: block;
            }
        }
        .faq-heading {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.25rem;
            @include md-up {
                gap: 0.5rem;
            }
            text-align: center;
        }
    }
    .before-and-after-section {
        padding: 2rem 0;
        @include md-up {
            padding: 3rem 0;
        }
        h2 {
            text-align: center;
            margin-bottom: 1rem;
            @include md-up {
                margin-bottom: 2rem;
            }
        }
        &__disclaimer {
            @apply container;
            max-width: 768px;
            margin-top: 2rem;
            @include md-up {
                text-align: center;
            }
        }
    }
    footer {
        z-index: 10;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 1px solid $grey-200;
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
        padding: 0.75rem;
        background-color: $white;
        gap: 1rem;
        a {
            margin: 0;
        }
    }
}
