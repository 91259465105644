@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';
@import 'src/scss/utilities/mixins';

.about-us {
    &__section {
        @include apply-vertical-section-padding;
    }

    &__doctor-section {
        @apply py-16 px-4 flex flex-col gap-12 items-center text-center;
        @include md-up {
            @apply gap-8
        }
        background-color: $white;
    }

    &__bottom {
        @apply py-12 text-left;
        @apply md:pt-0 md:pb-0;

        img {
            max-height: 18rem;
            width: auto;

            @include md-up {
                max-height: 23.75rem;
            }
        }
    }
}
