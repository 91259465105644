@import '../../../scss/utilities/breakpoints';
@import '../../../scss/variables/colours';
@import '../../../scss/variables/typography';
@import '../../../scss/variables/components';

$header-background: $white;

.header {
    position: relative;
    z-index: 100;
    .wrapper {
        position: relative;
        height: var(--header-height);
    }
    .header__section {
        transition: transform 200ms;
        box-shadow: $box-shadow-md;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        &:global(.headroom--pinned.headroom--not-top) {
            position: fixed;
            transform: translateY(0);
        }
        &:global(.headroom--unpinned) {
            position: relative;
            transform: translateY(-100%);
        }
        .header__button {
            width: auto;
        }
    }
    .header__links {
        display: none;
        @include xl-up {
            display: flex;
            align-items: center;
            gap: 3rem;
        }
        color: $black;
        a {
            position: relative;
            padding: 2rem 0;
            font-family: $font-maisonneue-book;
            &.header__link-active {
                font-family: $font-maisonneue-demi;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 6px;
                    background-color: $blue-light;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
            }
        }
    }
    &[data-variant='logo-only'] {
        .header__section {
            border-bottom: none;
            box-shadow: none;
        }
    }
}
