@import 'src/scss/utilities/breakpoints';
@import 'src/scss/variables';

.header--buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 0;
    .header--button {
        margin: auto;
        display: none;
        width: auto;
        @include sm-up {
            display: block;
        }
    }
    .header--account-button {
        display: none;
        @include xl-up {
            display: block;
        }
    }
}

.navigation {
    display: flex;
    align-items: center;
    @include sm-up {
        position: relative;
    }
    @include xl-up {
        display: none;
    }
    .navigation--toggle {
        z-index: 10;
    }
    .navigation--container {
        position: absolute;
        width: 0;
        top: 4rem;
        right: 0;
        transition: all 200ms;
        min-height: -webkit-fill-available;
        -webkit-overflow-scrolling: touch;
        background-color: $white;
        @include sm-up {
            top: -1rem;
            right: -1rem;
            padding-top: 2rem;
            height: auto;
            border: 0px solid transparent;
            border-radius: 1rem;
            box-shadow: $box-shadow-xl;
        }
        > * {
            opacity: 0;
            transition: all 200ms;
        }
        .navigation--content {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            height: calc(
                100dvh - 2rem - var(--header-height, 0rem) - var(
                        --top-bar-height,
                        0rem
                    )
            );
            @include sm-up {
                height: auto;
            }
            .navigation--item {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                color: $black;
                a {
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    font-family: $font-maisonneue-book;
                }
                .navigation--item-active {
                    font-family: $font-maisonneue-demi;
                }
                .profile-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1rem;
                    padding: 1rem;
                    border: 1px solid $grey-200;
                    border-radius: 0.5rem;
                    color: $purple-dark;
                    font-family: $font-maisonneue-demi;
                    margin-top: 1rem;
                    &:hover {
                        background-color: $grey-100;
                    }
                }
            }
            .navigation--buttons {
                margin: 1rem 0 0 0;
                .divider {
                    width: 100%;
                    text-align: center;
                    border-bottom: 1px solid $grey-200;
                    margin: 2rem 0 1.5rem;
                    line-height: 0.1em;
                    span {
                        margin: 0 1rem;
                        padding: 0 0.5rem;
                        background-color: $white;
                    }
                }
                @include sm-up {
                    display: none;
                }
            }
            .bottom-links {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                font-family: $font-maisonneue-demi;
                color: $grey-400;
                margin-top: 1rem;
                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 0.5rem;
                }
            }
        }
    }
    &[data-nav-open='true'] {
        .navigation--container {
            width: 100%;
            padding: 1rem 1.5rem 4rem 1.5rem;
            @include sm-up {
                width: 19rem;
                padding: 1rem;
                border: 1px solid $grey-200;
                .navigation--content {
                    margin-top: 1rem;
                }
            }
            > * {
                opacity: 1;
            }
        }
    }
}
