@import '../../scss/utilities/breakpoints';
@import '../../scss/variables';

.wrapper {
    display: flex;
    width: 100%;
    min-height: 20rem;
    background-color: $sand-light;
    position: relative;
    gap: 3rem;
    @include md-up {
        gap: 0;
    }
    .content {
        text-align: center;
        position: absolute;
        z-index: 1;
        padding: 3rem 1rem;
        @include md-up {
            flex: 2;
            position: relative;
            padding: 4rem 0rem;
        }
        h2 {
            margin-bottom: 1.5rem;
            @include lg-up {
                margin-bottom: 2rem;
            }
        }
    }
    .image-container {
        flex: 1;
        position: relative;
        img {
            opacity: 0.05;
            object-fit: cover;
        }
    }
}
