@import '../../scss/utilities/breakpoints';
@import '../../scss/variables';

.item-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include md-up {
        flex-direction: row;
    }
    gap: 1rem;
    .item-card {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        background-color: $white;
        border: 1px solid $grey-200;
        border-radius: 1rem;
        box-shadow: $box-shadow-md;
        width: 100%;
        @include md-up {
            width: 360px;
        }
        @include lg-up {
            width: 440px;
        }
    }
}
