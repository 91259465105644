.wrapper {
    @apply container mx-auto max-w-3xl rounded-lg border-2 bg-white p-4 lg:p-8;
    [data-heading] {
        > * {
            @apply text-lg sm:text-xl md:text-xxl;
        }
    }
}

.top {
    @apply mb-4 flex w-full flex-nowrap items-center justify-between;
}

.badge {
    @apply inline-flex items-center rounded-full bg-blue-light px-2 py-1 text-center font-demi text-xs uppercase tracking-widest md:text-base;
}

.tabs {
    @apply flex flex-nowrap;
    .tab {
        @apply border-b-2 border-grey-300 px-4 py-2 sm:px-8 md:px-16 xl:px-12;
    }
    [data-tab-active='true'] {
        @apply border-green-dark font-demi;
    }
}

.product {
    @apply flex w-full flex-wrap items-center lg:flex-nowrap lg:gap-4;
}
.image {
    @apply w-full md:w-1/2;
    img {
        @apply mx-auto max-h-[120px] w-auto py-2 md:max-h-[300px];
    }
    .active-ingredients {
        @apply mb-0 block md:hidden;
    }
}
.active-ingredients {
    @apply mb-4 w-full text-center font-demi text-regular;
}
.content {
    @apply w-full pt-4 sm:px-4 md:w-1/2 md:pt-8;
    .usp {
        @apply mb-4 w-full text-center;
        span {
            @apply mx-auto mb-4 inline-flex flex-nowrap items-center rounded-full border border-green-dark py-2 pl-2 pr-4 font-demi text-xs;
            svg {
                @apply mr-2 h-2 w-2 rounded-full border border-green-dark p-1;
            }
        }
    }
}

.slices {
    @apply mb-8;
}

.bottom {
    @apply flex w-full flex-wrap items-center justify-center sm:flex-nowrap sm:gap-4 md:block;
    .price {
        @apply mb-4 font-demi text-md md:mb-0;
    }
}

.references {
    @apply mt-6 md:mt-8;
    p {
        @apply text-[0.625rem] text-grey-400 md:text-xs;
    }
}
