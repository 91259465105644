@import '../../scss/utilities/breakpoints';
@import '../../scss/variables';

.wrapper {
    @apply container;
    width: 100%;
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    @include md-up {
        flex-direction: row;
        gap: 3rem;
    }

    .content {
        flex: 1;
        .description {
            display: flex;
            margin-top: 1rem;
            .line {
                border: {
                    left: 2px solid $grey-300;
                    bottom: 2px solid $grey-300;
                }
                border-bottom-left-radius: 2rem;
                margin: 0 1rem 1rem 0;
                padding-right: 1rem;
            }
            p {
                font-size: $font-regular;
                @include lg-up {
                    font-size: $font-medium;
                }
            }
        }
    }

    .image {
        position: relative;
        width: 100%;
        height: 13rem;
        @include md-up {
            flex: 1;
            height: 18rem;
        }
        img {
            border-radius: 1rem;
            object-fit: cover;
        }
    }
}
