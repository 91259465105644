@import 'src/scss/variables';
@import 'src/scss/utilities/breakpoints';

.page {
    background-color: $white;
    section {
        padding-top: 2rem;
        padding-bottom: 2rem;
        @include md-up {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
        > h2 {
            @apply container;
            max-width: 44rem;
            text-align: center;
        }
    }
    .hero-section {
        @apply container;
        &--list {
            @apply text-regular text-green-dark;
            @include lg-up {
                font-size: $font-medium;
            }
            li {
                @apply text-regular;
                @include lg-up {
                    font-size: $font-medium;
                }
                svg {
                    @apply pr-4 w-6 h-6;
                    @include between-zero-md {
                        @apply w-4 h-4;
                    }
                }
            }
        }
    }
    .info-grid-section {
        background-color: $sand-light;
        padding: 0;
    }
    .ingredients-section {
        @apply container;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        background-color: $white;
    }
    .comparison-section {
        h2 {
            @include md-up {
                margin-bottom: 1rem;
            }
        }
        background-color: $grey-100;
    }
    .doctor-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        background-color: $white;
        padding-left: 1rem;
        padding-right: 1rem;
        &--content {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            @include between-zero-md {
                @apply items-center;
            }
            @include md-up {
                flex-direction: row;
                width: 44rem;
            }
        }
    }
    .steps-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $grey-100;
        &--content {
            @apply container;
            margin-top: 2rem;
            @include md-up {
                margin-top: 3rem;
            }
        }
    }
    .footer-section {
        background-color: $grey-100;
        padding-bottom: calc(3rem + var(--fixed-button-height));
        @include md-up {
            padding-bottom: 5rem;
        }
        h2 {
            text-align: left;
            padding: 0;
            margin: 0;
        }
    }
}
