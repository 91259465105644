@import '../../src/scss/utilities/breakpoints';
@import '../../src/scss/variables';

.section {
    @apply md:relative;

    img {
        @apply w-full md:max-h-[37.5rem] md:object-cover;
    }
}

.content {
    @apply -mt-12 w-full pb-8 text-left;
    @apply sm:py-8;
    @apply md:absolute md:top-1/2 md:mt-0 md:w-[70%] md:-translate-y-1/2 md:text-left;
    @apply lg:w-[70%] lg:px-0;
    @apply xxl:w-[70%];

    [data-heading] {
        @apply mb-4 lg:mb-6;
        > * {
            @apply inline max-w-none bg-blue-extra-light box-decoration-clone p-1 text-lg text-black;
            //bg overlapping text requires overriding h1 with the line height
            @apply md:box-decoration-clone md:p-2 md:leading-[1.3] #{!important};
            @apply lg:p-3 lg:text-[4rem];
            @include sm-up {
                font-size: clamp(2.5rem, 3.5vw, $font-huge);
            }
            @include phone-only {
                max-width: 320px;
                margin: 0 auto;
            }
        }
    }
    .description {
        @apply mb-6 mt-5 max-w-[440px];
        @apply text-black;
        @apply lg:mb-6 lg:mt-4 lg:text-md;
    }
}
