@import '../../scss/utilities/breakpoints';
@import '../../scss/variables';

.wrapper {
    width: 100%;
    color: $black;
    display: flex;
    flex-direction: column;
    .content {
        text-align: center;
        h4 {
            margin: 1.5rem 0 1rem 0;
            color: $purple-dark;
        }
    }
    .image {
        width: 100%;
        height: 13rem;
        position: relative;
        img {
            border-radius: 1rem;
            border: 1px solid $grey-200;
            object-fit: cover;
        }
    }
}
