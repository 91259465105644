@import '../../scss/variables/colours';

.social-media-list {
    @apply pt-4;
    display: flex;
    align-content: center;
    align-items: center;
}
.links {
    @apply mr-4;
    display: inline-block;
    svg {
        @apply w-6 h-6 p-2;
        &:hover {
            color: $blue-extra-light;
        }
    }
}
