@import 'src/scss/utilities/breakpoints';
@import 'src/scss/variables';

.wrapper {
    @apply flex flex-col rounded-2xl p-4 gap-4 w-full bg-white border;
    border-color: $grey-200;
    box-shadow: $box-shadow-md;
    .image {
        height: 200px;
        overflow: hidden;
        position: relative;
        img {
            @apply w-full h-full rounded-lg;
            border: 1px solid $grey-200;
            object-fit: cover;
        }
    }
    .content {
        @apply flex flex-col items-start gap-4;
        .step {
            @apply rounded-lg py-2 px-3 gap-2;
            background-color: $black;
            color: $white;
        }
        .text {
            @apply flex flex-col gap-3;
        }
    }
}
