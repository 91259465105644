@import 'src/scss/variables/components';

.comparison-cards {
    @apply flex flex-wrap;
    :global(.swiper-pagination) {
        @apply relative bottom-auto top-auto order-first;
        @apply static bottom-auto left-auto right-auto top-auto order-first mb-4 mt-1;
        @apply mx-auto flex w-auto flex-nowrap lg:hidden;
        border-radius: 6rem;
        width: auto !important;
        @apply bg-blue-extra-light;
        :global(.swiper-pagination-bullet) {
            @apply m-0 h-auto w-auto px-6 py-4 text-sm text-grey-400 opacity-100;
            @apply md:px-8 md:py-4;
            background-color: transparent;
            border-radius: 6rem;
        }
        :global(.swiper-pagination-bullet-active) {
            @apply -my-1 flex items-center bg-blue-light font-bolder text-black;
            box-shadow: $box-shadow-md;
        }
    }
    :global(.swiper-wrapper) {
        @apply items-center;
    }
}

$card-height: 600px;

.comparison-cards--card {
    @apply px-4;
    > div {
        height: calc(var(--card-height) - 2rem);
        @apply rounded-xl border-2 p-4;
        @apply border-grey-200;
    }
    &[data-card-featured='true'] {
        @apply px-0 pt-0;

        > div {
            height: auto;
            @apply bg-blue-extra-light text-black;
        }
    }
}

.comparison-cards--card--heading {
    @apply mb-2;
    @apply text-green-dark;
    > * {
        @apply text-md;
    }
}

.comparison-cards--card--includes {
    @apply mb-4;
    [data-card-featured='true'] & {
        @apply text-green-dark;
    }
}

.comparison-cards--card--price {
    @apply mb-2;
    @apply text-grey-400;
    [data-card-featured='true'] & {
        @apply text-green-dark;
    }
}

.comparison-cards--card--content {
    @apply -mx-4 border-t-2 border-grey-200 px-4 pt-4;
}
