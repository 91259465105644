@import '../../scss/variables';
@import '../../scss/utilities/breakpoints';

// Default heading classes

.wrapper {
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        font-family: $font-family-headings;
        line-height: 1.2;
        color: inherit;

        mark {
            font-family: $font-family-highlight;
            background: none;
            color: inherit;
        }
    }

    h1,
    .h1 {
        font-size: 2rem;
        @include lg-up {
            font-size: 4rem;
        }
    }

    h2,
    .h2 {
        font-size: 2rem;
        @include lg-up {
            font-size: 3rem;
        }
    }

    h3,
    .h3 {
        font-size: 1.5rem;
        @include lg-up {
            font-size: 2rem;
        }
    }

    h4,
    .h4 {
        font-family: $font-maisonneue-demi;
        font-size: 1.125rem;
        @include lg-up {
            font-size: 1.5rem;
        }
    }

    h5,
    .h5 {
        font-family: $font-maisonneue-demi;
        font-size: 1rem;
        @include lg-up {
            font-size: 1.125rem;
        }
    }

    p {
        margin-bottom: 0;
    }
}

// Helper classes

@mixin font-helpers {
    @each $name, $size in $font-helper-map {
        &-#{$name} {
            font-size: $size;
        }
    }
}

// Helper render

.font {
    @include font-helpers();
}

.item {
    color: inherit;
}
// Heading Styles

.wrapper {
    display: block;
    color: inherit;
    &-underline-block {
        > * {
            margin-bottom: 0;
            padding-bottom: 0;
        }
        margin-bottom: 1rem;
        border-bottom: 1px solid $green-dark;
    }
    &.centered {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;
    }

    &.alternative {
        > * {
            font-family: $font-family-bold;
        }
    }

    &.contained {
        @apply lg:w-1/2 mx-auto;
    }
}
