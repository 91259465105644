@import '../../scss/utilities/breakpoints';
@import '../../scss/utilities/mixins';
@import '../../scss/variables';

.wrapper {
    @apply flex gap-8 px-4;
    @include lg-up {
        @apply container;
        position: relative;
    }
}

.content {
    @include sm-only {
        @apply mb-2;
    }
    .list {
        @apply mb-6 text-green-dark md:mb-8;
        ul {
            li {
                svg {
                    @apply h-6 w-6 pr-4;
                    @include between-zero-md {
                        @apply h-4 w-4;
                    }
                }
            }
        }
    }

    .tiles {
        @apply flex-col;
        @include lg-up {
            @apply flex-row;
        }
        @include sm-only {
            @apply flex-row;
        }

        .tile {
            @apply flex flex-auto gap-4 rounded-2xl border border-grey-200 p-4;
            box-shadow: $box-shadow-md;
            @include sm-only {
                @apply flex-col gap-3;
            }

            .tile-image {
                @apply rounded-lg object-cover;
                @include sm-only {
                    @apply h-20 w-full;
                }
            }

            .tile-content {
                @apply flex flex-col items-start justify-between gap-6;
                @include sm-only {
                    @apply gap-3;
                }
            }
        }
    }
}
.hero-image {
    @apply flex justify-center;
    @include sm-only {
        @apply hidden;
    }
    img {
        @apply object-cover;
        border-radius: 300px 300px 16px 16px;
        width: auto;
        height: 100%;
        max-width: 100%;
    }
}

.cta {
    @apply hidden;
    @include sm-only {
        @apply block px-4;
    }

    .btn_wrapper {
        &--default {
            @apply w-full text-center;
            @apply md:w-fit md:border-none md:px-0 md:py-0 md:text-left;
            transition: transform 200ms ease-in-out;
            @include between-zero-md {
                transform: translateY(100%);
                margin-top: -3rem;
                margin-bottom: 5rem;
                padding-top: 0.5rem;
            }
        }
        &--intersected {
            @include apply-sticky-bottom;
            @apply fixed bottom-0 left-0 z-50 w-full bg-white px-4;
            @apply md:relative md:w-fit md:border-0 md:border-none md:p-0 md:shadow-none;
            transform: translateY(0);
            animation: slideUp;
            transition: transform 200ms ease-in-out;
            padding-bottom: calc(env(safe-area-inset-bottom) + 0.75rem);
        }
    }
}

@keyframes slideUp {
    0%,
    10% {
        transform: translateY(100%);
        opacity: 0;
        transition: transform 200ms ease-in-out;
    }

    60%,
    100% {
        transform: translateY(0);
        opacity: 1;
        transition: transform 200ms ease-in-out;
    }
}
